import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-xl`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded-sm md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1545483656-1a34ae73add1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
      title: "Le handicap moteur",
      description:
        "Il recouvre l’ensemble des troubles pouvant entraîner une atteinte partielle ou totale de la motricité, notamment des membres supérieurs et/ou inférieurs (difficultés pour se déplacer, conserver ou changer une position, prendre et manipuler, effectuer certains gestes). Certaines déficiences motrices d’origine cérébrale peuvent également provoquer des difficultés pour s’exprimer, sans altération des capacités intellectuelles. En France, plus de 8 millions de personnes sont touchées par une déficience motrice, de la plus légère (rhumatisme, arthrose) à la plus lourde (hémiplégie, paraplégie, tétraplégie). Environ 600 000 personnes sont para-, tétra-ou hémiplégiques. Seules 370000 personnes utilisent un fauteuil roulant.",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1574887427561-d3d5d58c9273?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80",
      title: "Le handicap visuel",
      description:
        "Il concerne les personnes aveugles, mais aussi, dans la majorité des cas, les personnes malvoyantes. Dans certains métiers, une personne daltonienne peut-être reconnue comme handicapée. En France, 1 500 000 personnes sont malvoyantes, 60 000 sont aveugles.",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1596088869451-491e167efabb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2026&q=80",
      title: "Le handicap auditif",
      description:
        "La perte auditive totale est rare. Comme pour le handicap visuel, la plupart des déficients auditifs possèdent « des restes auditifs » pour lesquels les prothèses auditives apportent une réelle amplification. Selon les cas, ce handicap s’accompagne ou non, d’une difficulté à oraliser. Un certain nombre de personnes sourdes utilisent la langue des signes et d’autres la lecture sur les lèvres pour communiquer. Sur 6 millions de personnes sourdes et malentendantes, seulement 100 000 utilisent le langage gestuel.",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1650&q=80",
      title: "La déficience intellectuelle",
      description:
        "C’est une difficulté à comprendre et une limitation dans la rapidité des fonctions mentales sur le plan de la compréhension, des connaissances et de la perception. Les incapacités qui en découlent peuvent avoir des degrés différents et perturber l’acquisition de la mémorisation des connaissances, l’attention, la communication, l’autonomie sociale et professionnelle, la stabilité émotionnelle et le comportement…",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1578357078586-491adf1aa5ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
      title: "Le handicap psychique",
      description:
        "Aucune définition exhaustive n’est possible, d’autant que la terminologie psychiatrique (névrose, psychose…) est seulement maîtrisée par les psychiatres. Néanmoins on peut retenir que les personnes atteintes de difficultés d’ordre psychique souffrent d’un malaise qui peut se traduire, à certains moments, par des comportements déroutants pour les autres, car éloignés des conduites convenues et habituelles.",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1505751172876-fa1923c5c528?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aGVhbHRofGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      title: "Les maladies invalidantes",
      description:
        "Toutes les maladies respiratoires, digestives, parasitaires, infectieuses (diabète, hémophilie, sida, cancer, hyperthyroïdie…) peuvent entraîner des déficiences ou des contraintes plus ou moins importantes. Elles peuvent être momentanées, permanentes ou évolutives. Près de la moitié des maladies invalidantes sont d’origine respiratoire ou cardio-vasculaire.",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Accessibilité sur les formations</HeadingTitle>
          <HeadingDescription>
            En fonction du niveau d'handicap, je m'adapte pour leur fournir le
            meilleur accueil et la meilleure prise en charge. Je vous demande au
            préalable pour que j'adapte la formation à votre public d'en être
            informé.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
