import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js";
import ThreeColContactDetailsCardsClone from "components/cards/ThreeColContactDetailsClone.js";
import WithStepsAndImageFeatures from "components/features/TwoColWithSteps.js";
import SimpleWithSideImage from "components/faqs/SimpleWithSideImage";
import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";
import GetStartedCTA from "components/cta/GetStarted.js";

export default function MacSst() {

  const defaultCards = [
    {
      title: "Révision des gestes d'urgences",
    },
    {
      title: "Actualisation de la formation",
      description: (
        <ul>
          <li>- Risques de l'entreprise ou de l'établissement.</li>
          <li>- Modification du programme.</li>
        </ul>
      ),
    },
  ];

  const defaultSteps = [
    {
      heading:
        "Rendre le personnel capable de faire face à une situation d’accident de manière efficace et durable.",
    },
    {
      heading:
        "Acquérir, entretenir ou perfectionner ses connaissances afin de prévenir les accidents de manière à diminuer le taux de gravité.",
    },
    {
      heading:
        "Etre capable d’identifier les atteintes et d’effectuer les gestes sur nourrisson, enfant et adulte.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <WithStatsAndImageFeatures
        title="MAC SST"
        image="https://images.unsplash.com/photo-1617257484989-9f8ab411c362?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
        text={
          <>
            <p>
              Les stagiaires seront mis en situations réelles permettant de
              mettre à jour les gestes et comportements de premiers secours en
              attendant les secours.
            </p>
            <br />
            <p>
              La formation dure 7 heures. Il faut être titulaire du certificat
              de SST de moins de 24 mois. Elle est accessible à un groupe de
              stagiaire de 4 à 10 personnes.
            </p>
            <br />
            <p>
              Il s'agit d'une formation certifiante avec une mise à jour tous
              les 24 mois.
            </p>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone
      heading = "Formation SST de moins de 24 moins"
      description="Cette formation s'adresse à toute personne de l’entreprise titulaire du certificat de SST de moins de 24 mois."/>
      <ThreeColContactDetailsCards
        description="Cette formation a pour but de revérifier  et confirmer les connaissances utiles au sauveteur SST."
        defaultCards={defaultCards}
      />
      <WithStepsAndImageFeatures
        defaultSteps={defaultSteps}
        heading={
          <>
            La formation se déroule autour de{" "}
            <span tw="text-primary-500">3 grands axes</span>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone
        subheading="Méthodes et moyens pédagogiques"
        heading="Méthodes et moyens pédagogiques"
        description="Les stagiaires seront mis en situations réelles permettant de mieux assimiler les gestes et comportements."
      />
      <ThreeColContactDetailsCardsClone
        subheading="Évaluation"
        heading="Évaluation"
        description="QCM + document de synthèse remis aux stagiaires"
      />
      <SimpleWithSideImage price="680" />
      <GetStartedCTA text="Vous êtes intéressé par cette formation ?" />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
