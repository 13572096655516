import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";

const HeadingContainer = tw.div`text-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto`;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded-sm`;
const PostImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t-sm`}
`;
const PostText = tw.div`flex-1 px-6 py-8`;
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition-all duration-300 `;
const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
// const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`;
const AuthorTextInfo = tw.div`text-xs text-gray-600`;
const AuthorName = tw.div`font-semibold mt-2`;
// const AuthorProfile = tw.div`pt-1 font-medium`;

const HighlightedText = tw.span`text-primary-500`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full lg:w-2/3`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${PostImage} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t-sm sm:rounded-t-none sm:rounded-lg`}
      }
      ${PostText} {
        ${tw`pl-8 pr-5`}
      }
      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`mt-4 text-sm leading-relaxed`}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center`}
      }
    `}
`;

const DecoratorBlob1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;

export default ({
  subheading = "Mon offre",
  description = "Retrouvez ici l'ensemble des services que je propose",
  posts = [
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1513492448296-5f8b2ed8935a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=668&q=80",
      title: "Incendie",
      // description:
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris molestie id nisl vel euismod. Etiam quis tincidunt neque. Praesent quis leo sit amet enim commodo sodales.",
      url: "/incendie",
      featured: true,
    },
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1609868036123-99a6a59c280c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80",
      title: "Évacuation",
      // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      url: "/evacuation",
    },
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1617257484989-9f8ab411c362?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
      title: "MAC SST",
      // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      url: "/mac-sst",
    },
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1600959907703-125ba1374a12?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80",
      title: "SST",
      // description:
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris molestie id nisl vel euismod. Etiam quis tincidunt neque. Praesent quis leo sit amet enim commodo sodales.",
      url: "/sst",
      featured: true,
    },
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1604881988758-f76ad2f7aac1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80",
      title: "Une formation sur-mesure ?",
      description:
        "Trouvons ensemble la formation qui est faite pour vous et vos équipes.",
      url: "/contact",
      featured: true,
    },
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1544724569-5f546fd6f2b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80",
      title: "Habilitation électrique B0H0",
      // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      url: "/habilitation-electrique-b0h0",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>
            mon catalogue de <HighlightedText>services</HighlightedText>
          </Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post className="group" href={post.url}>
                <PostImage imageSrc={post.postImageSrc} />
                <PostText>
                  <PostTitle style={{ fontSize: "1.5em" }}>
                    {post.title}
                  </PostTitle>
                  <AuthorInfo>
                    {/* {post.featured && <AuthorImage src={post.authorImageSrc} />} */}
                    <AuthorTextInfo>
                      <AuthorName>{post.description}</AuthorName>
                    </AuthorTextInfo>
                  </AuthorInfo>
                </PostText>
              </Post>
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
