// import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import { FormspreeProvider } from "@formspree/react";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ComponentRenderer from "ComponentRenderer.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "views/Homepage";
import Formations from "views/Formations";
import FormationModel from "views/Formations/FormationModel";
import Contact from "views/Contact";
import Privacy from "views/Privacy";
import Terms from "views/Terms";
import Evacuation from "views/Formations/Evacuation";
import HabilitationElectriqueB0H0 from "views/Formations/HabilitationElectriqueB0H0";
import Incendie from "views/Formations/Incendie";
import MacSst from "views/Formations/MacSst";
import Sst from "views/Formations/Sst";
import Accessibilite from "views/Accessibilite";
import Pricing from "views/Pricing";
import Certificat from "views/Certificat";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route path="/formations">
          <Formations />
        </Route>
        <Route path="/formationModel">
          <FormationModel />
        </Route>
        <Route path="/contact">
          <FormspreeProvider project="1670074087013089276">
            <Contact />
          </FormspreeProvider>
        </Route>
        <Route path="/politique-de-confidentialite">
          <Privacy />
        </Route>
        <Route path="/cgu">
          <Terms />
        </Route>
        <Route path="/evacuation">
          <Evacuation />
        </Route>
        <Route path="/habilitation-electrique-b0h0">
          <HabilitationElectriqueB0H0 />
        </Route>
        <Route path="/incendie">
          <Incendie />
        </Route>
        <Route path="/mac-sst">
          <MacSst />
        </Route>
        <Route path="/sst">
          <Sst />
        </Route>
        <Route path="/accessibilite">
          <Accessibilite />
        </Route>
        <Route path="/tarifs">
          <Pricing />
        </Route>
        <Route path="/certificat">
          <Certificat />
        </Route>
      </Switch>
    </Router>
  );
}
