import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import CertificatMedia from "../images/certificat.jpeg";

export default function Certificat() {
  return (
    <AnimationRevealPage>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "5vh 0",
        }}
      >
        <img
          src={CertificatMedia}
          alt="Certification Qualiopi "
          style={{ width: "50vw" }}
        />
      </div>
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
