import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import GetStartedCTA from "components/cta/GetStarted.js";
import ThreePlansWithHalfPrimaryBackground from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";

export default function Pricing() {
  return (
    <AnimationRevealPage>
      <Header />
      <div style={{ marginTop: "10vh" }} />
      <ThreePlansWithHalfPrimaryBackground />
      <GetStartedCTA text="Besoin d'une formation sur-mesure ?" />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
