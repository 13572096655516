import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import KeyNumbers from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import TwoColumnWithImageAndRating from "components/testimonials/TwoColumnWithImageAndRating";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import GetStartedCTA from "components/cta/GetStarted.js";
import TwoColWithButton from "components/features/TwoColWithButton";

export default function Homepage() {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <TwoColWithButton />
      <Features
        subheading={<Subheading>Mon offre</Subheading>}
        heading={
          <>
          Un service <HighlightedText>sur-mesure</HighlightedText>
          </>
        }
        description="Je me déplace dans l'ouest de la France pour vous proposer une prestation de service sur-mesure et adaptée à vos besoins."
      />
      <KeyNumbers />
      <TwoColumnWithImageAndRating />
      <GetStartedCTA text="Besoin d'une formation ?" />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
