import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import TwoColContactUsFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

export default function Contact() {
  return (
    <AnimationRevealPage>
      <Header />
      <TwoColContactUsFullForm />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
