import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition-all duration-300`;

export default ({
  headingText = "Conditions générales d'utilisation du site Bernard Arpage Formation",
}) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Dernière mise à jour: 16 Mars 2023</p>
            <h1>Préambule</h1>
            <p>
              Les présentes conditions générales d'utilisation sont conclues
              entre :
            </p>
            <ul>
              <li>
                le gérant du site internet, ci-après désigné « l’Éditeur »,
              </li>
              <li>
                toute personne souhaitant accéder au site et à ses services,
                ci-après appelé « l’Utilisateur ».
              </li>
            </ul>
            <h1>Article 1 - Principes</h1>
            <p>
              Les présentes conditions générales d'utilisation ont pour objet
              l'encadrement juridique de l’utilisation du site Bernard Arpage
              Formation et de ses services.
            </p>
            <br />
            <p>
              Le site Internet https://arpageformation.com/ est un service de :
            </p>
            <ul>
              <li>L'entreprise indivuduelle Bernard Arpage Formation</li>
              <li>
                située Les Grimauderies - Les Alleuds 49320 Brissac Loire
                Aubance , France
              </li>
              <li>
                adresse URL du site :{" "}
                <Link
                  href="https://arpageformation.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://arpageformation.com/
                </Link>
              </li>
              <li>
                adresse mail :{" "}
                <Link href="mailto:bernard.arpage@gmail.com">
                  bernard.arpage@gmail.com
                </Link>
              </li>
              <li>
                n° de téléphone :{" "}
                <Link href="tel:+33661787192">+33 6 61 78 71 92</Link>
              </li>
            </ul>
            <br />
            <p>
              Les conditions générales d'utilisation doivent être acceptées par
              tout Utilisateur, et son accès au site vaut acceptation de ces
              conditions.
            </p>
            <h1>Article 2 - Evolution et durée des CGU</h1>
            <p>
              Les présentes conditions générales d'utilisation sont conclues
              pour une durée indéterminée. Le contrat produit ses effets à
              l'égard de l'Utilisateur à compter du début de l’utilisation du
              service.
            </p>
            <p>
              Le site Bernard Arpage Formation se réserve le droit de modifier
              les clauses de ces conditions générales d’utilisation à tout
              moment et sans justification.
            </p>
            <h1>Article 3 - Accès au site</h1>
            <p>
              Tout Utilisateur ayant accès à internet peut accéder gratuitement
              et depuis n’importe où au site Bernard Arpage Formation.
            </p>
            <p>
              Les frais supportés par l’Utilisateur pour y accéder (connexion
              internet, matériel informatique, etc.) ne sont pas à la charge de
              l’Éditeur.
            </p>
            <p>
              Le site et ses différents services peuvent être interrompus ou
              suspendus par l’Éditeur, notamment à l’occasion d’une maintenance,
              sans obligation de préavis ou de justification.
            </p>
            <p>
              L'Utilisateur du site a accès aux services suivants : Formation
              continue d'adultes.
            </p>
            <h1>Article 4 - Responsabilités</h1>
            <p>
              La responsabilité de l'Éditeur ne peut être engagée en cas de
              défaillance, panne, difficulté ou interruption de fonctionnement,
              empêchant l'accès au site ou à une de ses fonctionnalités.
            </p>
            <p>
              Le matériel de connexion au site utilisée est sous l'entière
              responsabilité de l'Utilisateur qui doit prendre toutes les
              mesures appropriées pour protéger le matériel et les données
              notamment d'attaques virales par Internet. L'utilisateur est par
              ailleurs le seul responsable des sites et données qu'il consulte.
            </p>
            <br />
            <p>
              L'Éditeur ne pourra être tenu responsable en cas de poursuites
              judiciaires à l'encontre de l'Utilisateur :
            </p>
            <ul>
              <li>
                du fait de l'usage du site ou de tout service accessible via
                Internet ;
              </li>
              <li>
                du fait du non-respect par l'Utilisateur des présentes
                conditions générales.
              </li>
            </ul>
            <br />
            <p>
              L'Éditeur n'est pas responsable des dommages causés à
              l'Utilisateur, à des tiers et/ou à l'équipement de l'Utilisateur
              du fait de sa connexion ou de son utilisation du site et
              l'Utilisateur renonce à toute action contre l'Éditeur de ce fait.
            </p>
            <p>
              Si l'Éditeur venait à faire l'objet d'une procédure amiable ou
              judiciaire à raison de l'utilisation du site par l'Utilisateur, il
              pourra retourner contre lui pour obtenir indemnisation de tous les
              préjudices, sommes, condamnations et frais qui pourraient découler
              de cette procédure.
            </p>
            <h1>Article 5 - Propriété intellectuelle</h1>
            <p>
              Tous les documents techniques, produits, photographies, textes,
              logos, dessins, vidéos, etc., sont soumis à des droits d'auteur et
              sont protégés par le Code de la propriété intellectuelle.
              Lorsqu'ils sont remis à nos clients, ils demeurent la propriété
              exclusive de Bernard Arpage Formation, seul titulaire des droits
              de propriété intellectuelle sur ces documents, qui doivent lui
              être rendus à sa demande.
            </p>
            <p>
              Nos clients s'engagent à ne faire aucun usage de ces documents,
              susceptible de porter atteinte aux droits de propriété
              industrielle ou intellectuelle du fournisseur et s'engagent à ne
              les divulguer à aucun tiers, en dehors d'une autorisation expresse
              et préalable donnée par l'Editeur.
            </p>
            <h1>Article 6 - Liens hypertextes</h1>
            <p>
              La mise en place par l'Utilisateur de tous liens hypertextes vers
              tout ou partie du site est strictement interdite, sauf
              autorisation préalable et écrite de l'Éditeur, sollicitée par
              courriel à l'adresse suivante :{" "}
              <Link href="mailto:bernard.arpage@gmail.com">
                bernard.arpage@gmail.com
              </Link>
              .
            </p>
            <p>
              L'Éditeur est libre de refuser cette autorisation sans avoir à
              justifier de quelque manière que ce soit sa décision. Dans le cas
              où l'Éditeur accorderait son autorisation, celle-ci n'est dans
              tous les cas que temporaire et pourra être retirée à tout moment,
              sans obligation de justification à la charge de l'Éditeur.
            </p>
            <p>
              Dans tous les cas, tout lien devra être retiré sur simple demande
              de l'Éditeur.
            </p>
            <p>
              Toute information accessible via un lien vers d'autres sites n'est
              pas sous le contrôle de l'Éditeur qui décline toute responsabilité
              quant à leur contenu.
            </p>
            <h1>Article 7 - Protection des données personnelles</h1>
            <h3>Données collectées</h3>
            <p>
              Les données à caractère personnel qui sont collectées sur ce site
              sont les suivantes :
            </p>
            <ul>
              <li>
                ouverture de compte : lors de la création du compte de
                l'utilisateur : nom ; prénom ; adresse électronique ; n° de
                téléphone ;
              </li>
              <li>
                connexion : lors de la connexion de l'utilisateur au site web,
                celui-ci enregistre, notamment, ses nom, prénom, données de
                connexion, d'utilisation, de localisation et ses données
                relatives au paiement ;
              </li>
              <li>
                profil : l'utilisation des prestations prévues sur le site web
                permet de renseigner un profil, pouvant comprendre une adresse
                et un numéro de téléphone ;
              </li>
              <li>
                paiement : dans le cadre du paiement des produits et prestations
                proposés sur le site web, celui-ci enregistre des données
                financières relatives au compte bancaire ou à la carte de crédit
                de l'utilisateur ;
              </li>
              <li>
                communication : lorsque le site web est utilisé pour communiquer
                avec d'autres membres, les données concernant les communications
                de l'utilisateur font l'objet d'une conservation temporaire ;
              </li>
              <li>
                cookies : les cookies sont utilisés, dans le cadre de
                l'utilisation du site. L'utilisateur a la possibilité de
                désactiver les cookies à partir des paramètres de son
                navigateur.
              </li>
            </ul>
            <h3>Utilisation des données personnelles</h3>
            <p>
              Les données personnelles collectées auprès des utilisateurs ont
              pour objectif la mise à disposition des services du site web, leur
              amélioration et le maintien d'un environnement sécurisé. Plus
              précisément, les utilisations sont les suivantes :
            </p>
            <ul>
              <li>accès et utilisation du site web par l'utilisateur ;</li>
              <li>gestion du fonctionnement et optimisation du site web ;</li>
              <li>
                organisation des conditions d'utilisation des Services de
                paiement ;
              </li>
              <li>
                vérification, identification et authentification des données
                transmises par l'utilisateur ;
              </li>
              <li>
                proposition à l'utilisateur de la possibilité de communiquer
                avec d'autres utilisateurs du site web ;
              </li>
              <li>mise en oeuvre d'une assistance utilisateurs ;</li>
              <li>
                personnalisation des services en affichant des publicités en
                fonction de l'historique de navigation de l'utilisateur, selon
                ses préférences ;
              </li>
              <li>
                prévention et détection des fraudes, malwares (malicious
                softwares ou logiciels malveillants) et gestion des incidents de
                sécurité ;
              </li>
              <li>gestion des éventuels litiges avec les utilisateurs ;</li>
              <li>
                envoi d'informations commerciales et publicitaires, en fonction
                des préférences de l'utilisateur.
              </li>
            </ul>
            <h3>Partage des données personnelles avec des tiers</h3>
            <p>
              Les données personnelles peuvent être partagées avec des sociétés
              tierces, dans les cas suivants :
            </p>
            <ul>
              <li>
                lorsque l'Utilisateur utilise les services de paiement, pour la
                mise en oeuvre de ces services, le site web est en relation avec
                des sociétés bancaires et financières tierces avec lesquelles
                elle a passé des contrats ;
              </li>
              <li>
                lorsque l'Utilisateur publie, dans les zones de commentaires
                libres du site web, des informations accessibles au public ;
              </li>
              <li>
                lorsque l'Utilisateur autorise le site web d'un tiers à accéder
                à ses données ;
              </li>
              <li>
                lorsque le site web recourt aux services de prestataires pour
                fournir l'assistance utilisateurs, la publicité et les services
                de paiement. Ces prestataires disposent d'un accès limité aux
                données de l'utilisateur, dans le cadre de l'exécution de ces
                prestations, et ont une obligation contractuelle de les utiliser
                en conformité avec les dispositions de la réglementation
                applicable en matière protection des données à caractère
                personnel ;
              </li>
              <li>
                si la loi l'exige, le site web peut effectuer la transmission de
                données pour donner suite aux réclamations présentées contre le
                site web et se conformer aux procédures administratives et
                judiciaires ;
              </li>
              <li>
                si le site web est impliquée dans une opération de fusion,
                acquisition, cession d'actifs ou procédure de redressement
                judiciaire, elle pourra être amenée à céder ou partager tout ou
                partie de ses actifs, y compris les données à caractère
                personnel. Dans ce cas, les utilisateurs seraient informés,
                avant que les données à caractère personnel ne soient
                transférées à une tierce partie.
              </li>
            </ul>
            <h3>Sécurité et confidentialité</h3>
            <p>
              Le site web met en oeuvre des mesures organisationnelles,
              techniques, logicielles et physiques en matière de sécurité du
              numérique pour protéger les données personnelles contre les
              altérations, destructions et accès non autorisés. Toutefois, il
              est à signaler qu'internet n'est pas un environnement complètement
              sécurisé et le site web ne peut pas garantir la sécurité de la
              transmission ou du stockage des informations sur internet.
            </p>
            <h3>Mise en oeuvre des droits des utilisateurs</h3>
            <p>
              En application de la réglementation applicable aux données à
              caractère personnel, les utilisateurs disposent des droits
              suivants, qu'ils peuvent exercer en faisant leur demande à
              l'adresse suivante :{" "}
              <Link href="mailto:bernard.arpage@gmail.com">
                bernard.arpage@gmail.com
              </Link>
            </p>
            <ul>
              <li>
                le droit d’accès : ils peuvent exercer leur droit d'accès, pour
                connaître les données personnelles les concernant. Dans ce cas,
                avant la mise en œuvre de ce droit, le site web peut demander
                une preuve de l'identité de l'utilisateur afin d'en vérifier
                l'exactitude.
              </li>
              <li>
                le droit de rectification : si les données à caractère personnel
                détenues par le site web sont inexactes, ils peuvent demander la
                mise à jour des informations.
              </li>
              <li>
                le droit de suppression des données : les utilisateurs peuvent
                demander la suppression de leurs données à caractère personnel,
                conformément aux lois applicables en matière de protection des
                données.
              </li>
              <li>
                le droit à la limitation du traitement : les utilisateurs
                peuvent de demander au site web de limiter le traitement des
                données personnelles conformément aux hypothèses prévues par le
                RGPD.
              </li>
              <li>
                le droit de s’opposer au traitement des données : les
                utilisateurs peuvent s’opposer à ce que ses données soient
                traitées conformément aux hypothèses prévues par le RGPD.
              </li>
              <li>
                le droit à la portabilité : ils peuvent réclamer que le site web
                leur remette les données personnelles qui lui sont fournies pour
                les transmettre à un nouveau site web.
              </li>
            </ul>
            <h3>Evolution de la présente clause</h3>
            <p>
              Le site web se réserve le droit d'apporter toute modification à la
              présente clause relative à la protection des données à caractère
              personnel à tout moment. Si une modification est apportée à la
              présente clause de protection des données à caractère personnel,
              le site web s'engage à publier la nouvelle version sur son site.
              Le site web informera également les utilisateurs de la
              modification par messagerie électronique, dans un délai minimum de
              15 jours avant la date d'effet. Si l'utilisateur n'est pas
              d'accord avec les termes de la nouvelle rédaction de la clause de
              protection des données à caractère personnel, il a la possibilité
              de supprimer son compte.
            </p>
            <h1>Article 8 - Cookies</h1>
            <p>
              Le site Bernard Arpage Formation peut collecter automatiquement
              des informations standards. Toutes les informations collectées
              indirectement ne seront utilisées que pour suivre le volume, le
              type et la configuration du trafic utilisant ce site, pour en
              développer la conception et l'agencement et à d'autres fins
              administratives et de planification et plus généralement pour
              améliorer le service que nous vous offrons.
            </p>
            <h1>Article 9 - Loi applicable</h1>
            <p>
              Les présentes conditions générales d'utilisation sont soumises à
              l'application du droit français. Si les parties n'arrivent pas à
              résoudre un litige à l'amiable, le litige sera soumis à la
              compétence des tribunaux français.
            </p>
            <br />
            <p>
              Ces{" "}
              <Link
                href="https://www.rocketlawyer.com/fr/fr/document/CGU-Conditions-generales-utilisation"
                target="_blank"
                rel="noopener noreferrer"
              >
                CGU Conditions générales d’utilisation
              </Link>{" "}
              ont été créées sur le site Rocket Lawyer.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
};
