import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js";
import ThreeColContactDetailsCardsClone from "components/cards/ThreeColContactDetailsClone.js";
import WithStepsAndImageFeatures from "components/features/TwoColWithSteps.js";
import SimpleWithSideImage from "components/faqs/SimpleWithSideImage";
import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";
import GetStartedCTA from "components/cta/GetStarted.js";

export default function HabilitationElectriqueB0H0() {
  const defaultCards = [
    {
      title: "Notions élémentaires d’électricité",
      description: (
        <ul>
          <li>- Qu’est-ce que l’électricité ?</li>
          <li>
            - Les unités usuelles, Volt, Ampère, Watt et leur point de mesure.
          </li>
        </ul>
      ),
    },
    {
      title: "Travaux d’ordre non électrique",
      description: (
        <ul>
          <li>
            - Définitions relatives aux ouvrages, opérations, zones et locaux.
          </li>
          <li>
            - Analyse générale des règles de sécurité décrites dans la
            publication NF C18-510.
          </li>
        </ul>
      ),
    },
    {
      title: "Appareillage de sectionnement, commande et protection",
      description: (
        <ul>
          <li>
            - Rôle et manipulation – Notions de surcharge et court-circuit.
          </li>
        </ul>
      ),
    },
    {
      title: "Opérations sur tout ou partie d'un ouvrage électrique",
      description: (
        <ul>
          <li>
            - Opérations hors tension (travaux non électriques en BT et HT).
          </li>
          <li>
            - Rôle des intervenants (chargé de consignation, de travaux,
            d'interventions et exécutants).
          </li>
          <li>- Consignes et documents écrits.</li>
          <li>- La mise en sécurité. Les procédures d’intervention BS.</li>
          <li>
            - Application sur une installation type (maquette pédagogique).
          </li>
          <li>- La distribution électrique ; le matériel électrique.</li>
          <li>- Les EPI.</li>
        </ul>
      ),
    },
    {
      title: "Le courant électrique et le corps humain",
      description: (
        <ul>
          <li>- Les différentes façons de s’électriser.</li>
          <li>- Les dangers du courant électrique ; La loi d’Ohm.</li>
          <li>- Les effets du courant électrique sur le corps humain.</li>
          <li>
            - Les règles de sécurité à appliquer pour se prémunir du danger.
          </li>
          <li>
            - Les mesures de prévention à prendre lors de l’exécution de tâches
            qui incombent normalement à l’intéressé.
          </li>
          <li>- Matériel de protection individuel et collectif.</li>
        </ul>
      ),
    },
    {
      title: "Incidents et accidents sur les ouvrages électriques",
      description: (
        <ul>
          <li>
            - Dispositions à prendre en cas d’accidents sur les équipements
            électriques.
          </li>
          <li>- Conduite à tenir en cas d’accident d’origine électrique.</li>
          <li>- Incendie sur les ouvrages électriques.</li>
          <li>- Evaluation des acquis par un test.</li>
        </ul>
      ),
    },
    {
      title: "Evolution de la réglementation en électricité",
    },
  ];

  const defaultSteps = [
    {
      heading:
        "Il peut accéder sans surveillance à un local réservé aux électriciens.",
    },
    {
      heading:
        "Il effectue ou dirige des travaux non électriques dans l'environnement de pièces nues et sous tension.",
    },
    {
      heading:
        "Il est responsable de sa propre sécurité ainsi que celle de ceux qui travaillent sous sa responsabilité.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <WithStatsAndImageFeatures
        title="Habilitation électrique B0H0"
        image="https://images.unsplash.com/photo-1544724569-5f546fd6f2b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
        text={
          <>
            <p>
              Formation pour le personnel d’exploitation ou d’entretien «non
              électricien» appelé à effectuer des opérations d’ordre
              non-électrique.
            </p>
            <br />
            <p>
              La formation dure 14 heures soit 2 jours consécutifs. Elle est
              accessible à un groupe de stagiaire de 4 à 12 personnes.
            </p>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone />
      <ThreeColContactDetailsCards
        description=""
        defaultCards={defaultCards}
      />
      <WithStepsAndImageFeatures
        defaultSteps={defaultSteps}
        heading={
          <>
            La formation se déroule autour de{" "}
            <span tw="text-primary-500">3 grands axes</span>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone
        subheading="Méthodes et moyens pédagogiques"
        heading="Méthodes et moyens pédagogiques"
        description="Les stagiaires seront mis en situations réelles permettant de mieux assimiler les gestes et comportements."
      />
      <ThreeColContactDetailsCardsClone
        subheading="Évaluation"
        heading="Évaluation"
        description="QCM + document de synthèse remis aux stagiaires"
      />
      <SimpleWithSideImage price="630" />
      <GetStartedCTA text="Vous êtes intéressé par cette formation ?" />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
