import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js";
import ThreeColContactDetailsCardsClone from "components/cards/ThreeColContactDetailsClone.js";
import WithStepsAndImageFeatures from "components/features/TwoColWithSteps.js";
import SimpleWithSideImage from "components/faqs/SimpleWithSideImage";
import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";
import GetStartedCTA from "components/cta/GetStarted.js";

export default function Evacuation() {
  const defaultCards = [
    {
      title: "Organisation de la lutte contre le feu",
      description: (
        <ul>
          <li>- Connaître le rôle d’une équipe de première intervention.</li>
          <li>
            - Identifier les moyens d’extinction mis à disposition dans
            l’entreprise.
          </li>
        </ul>
      ),
    },
    {
      title: "Les phénomènes de l’incendie",
      description: (
        <ul>
          <li>- Les mécanismes et effets de la combustion.</li>
          <li>- La classification des feux.</li>
          <li>- La propagation du feu et des fumées.</li>
        </ul>
      ),
    },
    {
      title: "La lutte contre l’incendie",
      description: (
        <ul>
          <li>- Les agents extincteurs et leurs principes d’action.</li>
          <li>- Les extincteurs mobiles et les Robinets d’Incendie Armés.</li>
        </ul>
      ),
    },
    {
      title: "Exercices sur Générateur de flammes écologique",
      description: (
        <ul>
          <li>
            - Manipuler un extincteur à eau pulvérisée de 6 litres et agir
            efficacement sur le feu.
          </li>
          <li>
            - Manipuler un extincteur CO2 de 2 Kg et agir efficacement sur le
            feu.
          </li>
          <li>
            - Présenter les effets provoqués par l’explosion d’un aérosol de 300
            ml.
          </li>
        </ul>
      ),
    },
    {
      title: "Exercices sur feux réels (si le site le permet)",
      description: (
        <ul>
          <li>
            - Se familiariser aux phénomènes de la combustion (chaleur et
            fumées).
          </li>
          <li>
            - Agir sur un feu d’huile et prendre en compte le risque de
            Boil-Over.
          </li>
        </ul>
      ),
    },
    {
      title: "Les moyens permettant d’organiser l’évacuation",
      description: (
        <ul>
          <li>
            - Les consignes et procédures de sécurité interne à l’établissement.
          </li>
          <li>- Les plans d’évacuation, l’éclairage de sécurité.</li>
          <li>- Le point de rassemblement.</li>
        </ul>
      ),
    },
    {
      title: "L’organisation humaine des chargés d’évacuation",
      description: (
        <ul>
          <li>- Le comportement des occupants à évacuer.</li>
          <li>
            - Le rôle des différents acteurs de l’évacuation (Responsable,
            Serre-files, Guides d’évacuation, Chargé du contrôle nominatif).
          </li>
        </ul>
      ),
    },
    {
      title: "Visite commentée de l’établissement",
      description: (
        <ul>
          <li>- Reconnaissance des itinéraires à emprunter.</li>
          <li>
            - Localiser et identifier les moyens permettant d’organiser
            l’évacuation dans l’entreprise.
          </li>
        </ul>
      ),
    },
    {
      title: "Exercice d’évacuation",
      description: (
        <ul>
          <li>
            - Avec l’accord du client, utiliser un générateur de fumée pour
            mettre en conditions réelles les personnels et les chargés
            d’évacuation.
          </li>
          <li>
            - Une synthèse sera faite avec l’ensemble du personnel pour donner
            les axes d’amélioration et rappeler les consignes et procédures
            d’évacuation interne à l’établissement.
          </li>
        </ul>
      ),
    },
  ];

  const defaultSteps = [
    {
      heading: "Savoir réagir face à un départ de feu.",
    },
    {
      heading: "Savoir donner l’alarme et l’alerte.",
    },
    {
      heading: "Savoir utiliser les moyens de première intervention.",
    },
    {
      heading: "Connaître les dangers des fumées.",
    },
    {
      heading: "Connaître les consignes spécifiques de l’établissement.",
    },
    {
      heading: "Connaître le rôle des différents acteurs de l’évacuation.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <WithStatsAndImageFeatures
        title="Evacuation"
        image="https://images.unsplash.com/photo-1609868036123-99a6a59c280c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80"
        text={
          <>
            <p>
              Les stagiaires seront mis en situations réelles permettant de
              mieux assimiler les gestes et comportements.
            </p>
            <br />
            <p>
              La formation dure 4 heures et elle est accessible à un groupe de
              stagiaire de 4 à 12 personnes. Le code du travail oblige
              l'exploitant à réaliser un exercice d'évacuation par semestre.
            </p>
            <br />
            <p>
              Des document de synthèse et un livret pédagogique sont remis à
              chaque stagiaire à la fin de la formation.
            </p>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone />
      <ThreeColContactDetailsCards
        description="Cette formation s'adresse à l'ensemble du personnel d’un établissement et plus particulièrement les personnes désignées « EPI » par le chef d’établissement."
        defaultCards={defaultCards}
      />
      <WithStepsAndImageFeatures
        defaultSteps={defaultSteps}
        heading={
          <>
            La formation se déroule autour de{" "}
            <span tw="text-primary-500">6 grands axes</span>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone
        subheading="Méthodes et moyens pédagogiques"
        heading="Méthodes et moyens pédagogiques"
        description="Les stagiaires seront mis en situations réelles permettant de mieux assimiler les gestes et comportements."
      />
      <ThreeColContactDetailsCardsClone
        subheading="Évaluation"
        heading="Évaluation"
        description="QCM + document de synthèse remis aux stagiaires"
      />
      <SimpleWithSideImage price="630" />
      <GetStartedCTA text="Vous êtes intéressé par cette formation ?" />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
