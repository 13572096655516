import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import SimpleContactUs from "components/forms/SimpleContactUs";
import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js";
import WithStepsAndImageFeatures from "components/features/TwoColWithSteps.js";
import SimpleWithSideImage from "components/faqs/SimpleWithSideImage";
import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <WithStatsAndImageFeatures
        title="Nom de la formation"
        text="Description // Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget orci erat. Nunc faucibus at lectus id congue. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras sem tellus, varius luctus odio eget, lobortis porta ante. Etiam quis odio nulla. Ut laoreet porttitor gravida. Nullam egestas ex lectus, id feugiat diam vulputate sed. Nunc in lectus ut sapien eleifend tincidunt. Mauris in mi varius mi bibendum vehicula in maximus elit. Cras vitae eleifend erat. "
      />
      <ThreeColContactDetailsCards />
      <WithStepsAndImageFeatures />
      <SimpleWithSideImage />
      <SimpleContactUs />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
};
