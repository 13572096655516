import React from "react";
import tw from "twin.macro";
// import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo-horizontal.svg";
/* import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg"; */

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition-all duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40`;
// const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

// const SocialLinksContainer = tw.div`mt-4 `;
/* const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition-all duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`; */

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <CompanyDescription>
            Consultant en sécurité incendie et en secours à la personne depuis
            plus de 20 ans, je forme et je réalise des audits pour tous types de
            public et tous types de structures.
            <br /><br />
            <i>Version 3.0 du 01/01/2024</i>
          </CompanyDescription>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
        </WideColumn>
        <Column>
          <ColumnHeading>Formations</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/incendie">Incendie</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/evacuation">Évacuation</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/sst">SST</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/mac-sst">MAC SST</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/habilitation-electrique-b0h0">
                Habilitation éléctrique B0H0
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Informations</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/cgu">CGU</Link>
            </LinkListItem>
            <LinkListItem>
              <Link
                href="/politique-de-confidentialite"
                style={{ lineHeight: "2em" }}
              >
                Mentions légales & politique de confidentialité
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/accessibilite">
                Accessibilités sur les formations
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/tarifs">Grille tarifaire</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Contact</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="tel:+33661787192">+33 6 61 78 71 92</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="mailto:bernard.arpage@gmail.com">
                bernard.arpage@gmail.com
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/contact">Formulaire de contact</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
