import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import GridWithFeaturedPost from "components/blogs/GridWithFeaturedPost";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";

export default function Formations() {
  return (
    <AnimationRevealPage>
      <Header />
      <GridWithFeaturedPost />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
