import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import VerticalWithAlternateImageAndTextFeatures from "components/features/VerticalWithAlternateImageAndText.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import GetStartedCTA from "components/cta/GetStarted.js";

export default function Accessibilite() {
  return (
    <AnimationRevealPage>
      <Header />
      <VerticalWithAlternateImageAndTextFeatures />
      <GetStartedCTA
        text="Des questions ? Discutons-en ensemble"
        primaryLinkText="Me contacter"
      />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
