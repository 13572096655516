import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import SimpleFiveColumn from "components/footers/SimpleFiveColumn";
import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js";
import ThreeColContactDetailsCardsClone from "components/cards/ThreeColContactDetailsClone.js";
import WithStepsAndImageFeatures from "components/features/TwoColWithSteps.js";
import SimpleWithSideImage from "components/faqs/SimpleWithSideImage";
import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";
import GetStartedCTA from "components/cta/GetStarted.js";

export default function Sst() {
  const defaultCards = [
    {
      title: "Le Sauvetage Secourisme du Travail",
      description: (
        <ul>
          <li>- Les accidents du travail.</li>
          <li>- Prévention des risques professionnels.</li>
          <li>- Le rôle du Sauveteur Secouriste du Travail.</li>
        </ul>
      ),
    },
    {
      title: "La conduite à tenir par le secouriste",
      description: (
        <ul>
          <li>- Identifier les sources de risques réelles ou supposées.</li>
          <li>- Rechercher les risques persistants et se protéger.</li>
          <li>- Repérer les personnes exposées.</li>
        </ul>
      ),
    },
    {
      title: "L’alerte des secours",
      description: (
        <ul>
          <li>- L’organisation de l’alerte dans l’entreprise.</li>
          <li>- Faire donner l’alerte, les numéros d’urgence.</li>
          <li>- Le message d’alerte, l’accueil des secours.</li>
        </ul>
      ),
    },
    {
      title: "Examiner la victime",
      description: (
        <ul>
          <li>- Saignement abondant.</li>
          <li>- Etouffement.</li>
          <li>- La victime qui respire mais n’est pas consciente.</li>
          <li>- La victime ne respire pas.</li>
          <li>- La victime souffre de malaise.</li>
          <li>- Les brûlures.</li>
          <li>- Les fractures.</li>
          <li>- Les membres sectionnés.</li>
          <li>- Les plaies spéciales.</li>
        </ul>
      ),
    },
    {
      title: "Secourir",
      description: (
        <ul>
          <li>- Techniques d’évacuations.</li>
          <li>- Compression manuelle.</li>
          <li>- Les méthodes de désobstruction et Heimlich.</li>
          <li>- La position latérale de sécurité.</li>
          <li>- La ranimation cardio pulmonaire avec Défibrillateur.</li>
          <li>- Conditionnement d’une victime souffrant d’un malaise.</li>
          <li>- Traitement de brûlure thermique et chimique.</li>
          <li>- Traitement des fractures ouvertes ou non.</li>
          <li>- Conditionnement de membre sectionné, pansement compressif.</li>
          <li>
            - Conditionnement d’une plaie à l’œil, au thorax, à l’abdomen.
          </li>
        </ul>
      ),
    },
  ];

  const defaultSteps = [
    {
      heading:
        "Rendre le personnel capable de faire face à une situation d’accident de manière efficace et durable.",
    },
    {
      heading:
        "Acquérir, entretenir ou perfectionner ses connaissances afin de prévenir les accidents de manière à diminuer le taux de gravité.",
    },
    {
      heading:
        "Etre capable d’identifier les atteintes et d’effectuer les gestes sur nourrisson, enfant et adulte.",
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      <WithStatsAndImageFeatures
        title="SST"
        image="https://images.unsplash.com/photo-1600959907703-125ba1374a12?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
        text={
          <>
            <p>
              Les stagiaires seront mis en situations réelles permettant de
              mieux assimiler les gestes et comportements de premiers secours en
              attendant les secours.
            </p>
            <br />
            <p>
              La formation dure 14 heures soit 2 jours consécutifs. Elle est
              accessible à un groupe de stagiaire de 4 à 10 personnes.
            </p>
            <br />
            <p>
              Il s'agit d'une formation certifiante avec une mise à jour tous
              les 24 mois.
            </p>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone />
      <ThreeColContactDetailsCards
        description="Cette formation s'adresse à toute personne de l’entreprise désignée et volontaire pour donner les premiers soins en attendant les secours."
        defaultCards={defaultCards}
      />
      <WithStepsAndImageFeatures
        defaultSteps={defaultSteps}
        heading={
          <>
            La formation se déroule autour de{" "}
            <span tw="text-primary-500">3 grands axes</span>
          </>
        }
      />
      <ThreeColContactDetailsCardsClone
        subheading="Méthodes et moyens pédagogiques"
        heading="Méthodes et moyens pédagogiques"
        description="Les stagiaires seront mis en situations réelles permettant de mieux assimiler les gestes et comportements."
      />
      <ThreeColContactDetailsCardsClone
        subheading="Évaluation"
        heading="Évaluation"
        description="QCM + document de synthèse remis aux stagiaires"
      />
      <SimpleWithSideImage price="1280" />
      <GetStartedCTA text="Vous êtes intéressé par cette formation ?" />
      <SimpleFiveColumn />
    </AnimationRevealPage>
  );
}
